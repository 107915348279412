<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                Grant Cycle B Reviews
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">
                <div class="col-lg-12">
                  <div class="inner">
                    <div class="section-title">
                      <h1 class="heading-title">
                        Karin Unruh - Bondurant Place-Based Learning Grant
                      </h1>
                      <h4>Relevance to RFP</h4>
                      <p>
                        Karin's grant proposal for the Bondurant Elementary
                        One-Room School History Project aligns with the RFP by
                        addressing the need for place-based learning projects
                        that involve local community members and engage students
                        in collecting and preserving historical information. The
                        proposal focuses on Bondurant Elementary School, an
                        essential community institution. The project will
                        involve students conducting oral interviews with past
                        and current residents of Bondurant, Wyoming, as well as
                        past students and teachers, to gather as much history of
                        the school as possible. The project will culminate in a
                        virtual history book and video interviews that will be
                        posted on the Bondurant Community Club website.
                      </p>
                      <h4>Abstract</h4>
                      <p>
                        The abstract provides a clear and concise summary of the
                        Bondurant Elementary One-Room School History Project,
                        proposed by Karin. It outlines the main goal of the
                        project, which is to gather and compile the history of
                        Bondurant Elementary School through conducting oral
                        interviews with past and current residents, students,
                        and teachers. The abstract also highlights the broader
                        impact of the project on the Bondurant community and
                        provides information on the timeline and budget for the
                        project.
                      </p>
                      <h4>Proposal Narrative</h4>
                      <p>
                        Karin's proposal narrative is well-written and provides
                        a detailed description of the Bondurant Elementary
                        One-Room School History Project. It includes information
                        about the participants served, which are the students
                        who attend Bondurant Elementary School and the
                        Bondurant, Wyoming community members who will provide
                        interviews and resources. The proposal also provides
                        information about Karin as the grant applicant and
                        manager, who has the experience and expertise required
                        to carry out the project. The narrative explains the
                        purpose of the project, which is to preserve the history
                        of Bondurant Elementary School through conducting
                        interviews and creating a virtual history book. The
                        proposal also describes the community impact of the
                        project, which will benefit the entire Bondurant
                        community, as well as Sublette Country at large. The
                        proposal includes a timetable for the project, which
                        outlines the timeline for conducting interviews and
                        creating the virtual history book, as well as a program
                        evaluation plan.
                      </p>
                      <h4>Budget</h4>
                      <p>
                        The budget worksheet, proposed by Karin, is
                        well-organized and clear, providing a detailed breakdown
                        of the costs associated with the project. The budget
                        justification is also well-written, providing a clear
                        rationale for each item and explaining why it is
                        necessary for the project. The proposed budget of $2,000
                        is reasonable and appropriate for the scope of the
                        project.
                      </p>
                      <h4>Broader Impacts Section</h4>
                      <p>
                        The broader impacts are interwoven throughout the
                        proposal and outlines how the project, proposed by
                        Karin, will benefit the Bondurant community, as well as
                        Sublette Country at large. The section highlights the
                        significance of preserving the history of Bondurant
                        Elementary School, an important community institution.
                        It also emphasizes the educational benefits of the
                        project for the students who will be conducting
                        interviews and creating the virtual history book. The
                        section could be further enhanced by discussing how the
                        project could serve as a model for other place-based
                        learning projects in rural communities.
                      </p>
                      <h4>Overall Proposal</h4>
                      <p>
                        Overall, Karin's Bondurant Elementary One-Room School
                        History Project proposal is well-written and meets all
                        the essential components of a grant proposal. It is
                        relevant to the RFP, provides a clear overview of the
                        project, and includes a detailed proposal narrative,
                        budget, and program evaluation plan. The proposal also
                        describes the broader impact of the project and
                        highlights the significance of preserving the history of
                        Bondurant Elementary School. The proposal is
                        well-structured and easy to follow, making it an
                        effective grant proposal.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">
                <div class="col-lg-12">
                  <div class="inner">
                    <div class="section-title">
                      <h1 class="heading-title">
                        ​William Heppding - Adult Online Education Participation
                      </h1>
                      <h4>Relevance to RFP</h4>
                      <p>
                        The proposed research by Williams aligns with the goals
                        and selection criteria of the NAED/Spencer Dissertation
                        Fellowship Program. The proposal focuses on the
                        significance of education research, specifically in the
                        area of adult learners and online education. Williams'
                        proposal is highly relevant to the RFP as it provides a
                        well-defined research question, methodology, and
                        expected outcomes.
                      </p>
                      <h4>Abstract</h4>
                      <p>
                        Williams' abstract provides a clear and concise overview
                        of the proposed research, emphasizing the importance of
                        studying adult learners in online education. It briefly
                        outlines the research questions, approaches, and
                        expected outcomes.
                      </p>
                      <h4>Proposal Narrative</h4>
                      <p>
                        William's proposal narrative provides a clear and
                        detailed explanation of the research question, the
                        proposed research approach, and the potential impact of
                        the research on education. The proposal highlights the
                        importance of online education for adult learners and
                        identifies potential avenues for research. The narrative
                        also demonstrates William's expertise and experience in
                        the field.
                      </p>
                      <h4>Budget</h4>
                      <p>
                        Williams' proposal does not include a budget section,
                        which makes it difficult to provide feedback on this
                        aspect of the proposal. Nevertheless, it is crucial to
                        ensure that the budget is reasonable and aligns with the
                        proposed research activities.
                      </p>
                      <h4>Broader Impacts Section</h4>
                      <p>
                        The broader impacts of Williams' proposal provides a
                        thoughtful discussion of how the proposed research could
                        contribute to the field of education. The proposal
                        highlights the potential impact of the research on
                        understanding the motivations of adult learners in
                        pursuing online education and how this information could
                        inform educational policies and practices. Furthermore,
                        the proposal addresses potential equity issues related
                        to access to higher education for adult learners.
                      </p>
                      <h4>Overall Proposal</h4>
                      <p>
                        In general, Williams' proposal is well-written and
                        addresses an important topic in education research. The
                        proposal provides a clear and specific research
                        question, methodology, and expected outcomes. The
                        proposal also provides a thoughtful discussion of the
                        potential broader impacts of the research on education
                        and equity issues. However, the proposal could benefit
                        from adding a budget section and providing more detail
                        on the potential impact of the research on education.
                      </p>

                      <div class="portfolio-details-btn mt--30">
                        <router-link
                          class="btn-default btn-border"
                          to="/LDTE5870-3"
                          >Back to LDTE 5870</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
import feather from "feather-icons";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      items: [
        {
          thumb: require("../../assets/images/portfolio/portfolio-big-03.jpg"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      listOne: [
        {
          id: 1,
          icon: "check",
          desc: `5 PPC Campaigns the majority.`,
        },
        {
          id: 2,
          icon: "check",
          desc: `Digital Marketing going to.`,
        },
        {
          id: 3,
          icon: "check",
          desc: ` Marketing Agency passage of.`,
        },
        {
          id: 4,
          icon: "check",
          desc: ` Seo Friendly you are going.`,
        },
      ],
      socialList: [
        {
          url: "https://www.facebook.com/",
          icon: "facebook",
        },
        {
          url: "https://www.linkedin.com/",
          icon: "linkedin",
        },
        {
          url: "https://instagram.com/",
          icon: "instagram",
        },
        {
          url: "https://twitter.com/",
          icon: "twitter",
        },
      ],
      moreProjects: [
        {
          src: require("../../assets/images/portfolio/related-image-01.jpg"),
          title: "Digital Analysis",
          categorie: "Technique",
        },
        {
          src: require("../../assets/images/portfolio/related-image-02.jpg"),
          title: "Plan Management",
          categorie: "Planning",
        },
      ],
      index: null,
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
